:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;    
     /*General*/
    --container-max-width: 1500px;
    --fixed-header-height: 80px; 
    /* Slick */
    --slick-slider-height: 100vh;
    /* change settings for headings */
    --text-color: #777;
    --body-bg: #fff;
    --main-color: #fff;
    --accent-color: #000;
    --effect-color: #eee;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-li-a-padding: 10px 15px;
    --link-color: inherit;
    --link-hover-color: inherit;
    --map-marker-color: 000;    
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 15px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;   
    /* Fonts */
    --base-font-family: Raleway, Helvetica, Arial, sans-serif;
    --alternative-font-family: Raleway, Helvetica, Arial, sans-serif;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Karla/all.css"; /* 400, 400i, 700, 700i */
@import "/fonts/Raleway/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 500, 500i, 700, 700i, 900, 900i */
@import "/fonts/Quicksand/all.css"; /* 300, 300i, 400, 400i, 700, 700i */
/*@import "/cssmodules/animate.css";*/

/*--------------------------------------------
General
---------------------------------------------*/


/*---------------------------------------------------------
TILL SUPPORTEN: Ta bort denna när övriga språk är inlagda
-----------------------------------------------------------*/

li.localeswitcher {
    display: none;
} 

/* Over and out
----------------------------------------------------------*/

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height: 100%;
}

body {
    /*background: url('/theme/intendit/graphics/bg.png'); */
    background: var(--body-bg);
    font-family: var(--base-font-family);
    letter-spacing: .1rem;
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

main {
    margin-top: 200px;
}

.homepage main {
    margin-top: 0;
} 

h1, h2, h3, h4 {
    font-family: var(--alternative-font-family);
    font-weight: 100;
}

h1 {
    font-size: calc(1.5 * var(--scale-factor) * var(--scale-factor)rem);
}

h1.twelve {
    max-width: 100%;
}

.container h1 {
    margin: 2rem 0.8rem;
}

h2 {
    font-size: calc(1.5 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.10rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {
    background: #fff;
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 180px;
}

header .container {
    padding: 0 0.5em;
}

header a {
    text-decoration: none;
}

.logo img {
    max-width: 220px;
    height: auto;
    padding: 1rem;
    margin-top: -70px;
}

.logo a {
    font-size: 0;
}

.booking-button {/*
    background: #4C4C4C;
    color: #fff;*/
    padding: 1rem;
    border-radius: 10px;
    font-size: .7rem;
    margin-top: -0px;
}

.topcontact {
    padding-right: 1rem;
    padding-top: 1rem;
}

.topcontact svg {
    fill: #fff;
    margin-right: 1rem;
    transition: .5s ease-in;
}

.topcontact svg:hover {
    fill: #000;
}

.round {
    background: rgba(0,0,0,.7);
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    width: 40px;
    margin: 0 10px 10px 0;
    transition: .5s ease-in;
}

.round:hover {
    background: #fff;
}


.round .fa-icon {
    fill: #fff;
    padding: 0px 10px;
    height: 17px;
    width: auto;
    display: block;
    margin: 0 auto;
    margin-top: 13px;
}

.round .fa-icon[aria-label="Icon depicting mobile"] {
    padding: 3px 0;
}

.menu li, .menu ul, .menu.menu {

}

.menu li {
    margin: 0;
}

.menu li a {
    font-family: var(--alternative-font-family);
    padding: 10px;
    color: #777;
    transition: 0.5s ease-in;
    text-transform: uppercase;
    font-size: .9rem;
}

.menu li a:hover, .menu .active a {
    border-top: 1px solid #777;
    border-bottom: 1px solid #777;
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a {
    margin: 0;
    font-size: .7rem;
    background: #fff;
    border: none;

}

.menu.menu .sub-menu-items li a:hover {
    border: none;
}

/**** Leftmenu ****/

.submenu ul {
    margin-top: 2.8rem;
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
}

.submenu ul li a {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

/*--------------------------------------------------------
Slider startsida
---------------------------------------------------------*/
.slick-slider {
    margin-bottom: 0;
}

.topslider .slick-slide {
    height: var(--slick-slider-height);
}

.topslider .slick-slide {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}


.introtext-outer {
    display: flex;
    position: absolute;
    top: 45vh; 
    max-width: 30%;
    left: 10%; 
}

.introtext-inner  {
    width: 60vw;
    margin: auto;
    text-align: center;
    background: rgba(255,255,255,.8);
    padding: 3rem;
}

.introtext-inner h1 {
    font-family: var(--alternative-font-family);
    font-size: 1.4rem;
    text-shadow: 1px 1px 0px #fff;
    color: #222;
    z-index: 99;
    position: relative;
    font-weight: 400;
}

/*Slick arrows*/
.slick-prev, .slick-next {width:100px; height:100px; border:none;position:absolute; z-index:1; }  
.slick-prev {left:0px;} .slick-next {right:0px;}
.slick-prev:before, .slick-next:before { font-size:100px; color:#fff; opacity:0.9; font-family: Quicksand, sans-serif; }
.slick-prev:before {content:"<";} .slick-next:before {content:">";}


/* Content-startpage | Blocks
---------------------------------------------------------*/

.block-2 {
    margin: 0 auto;
    text-align: center;
}

/* Content - all
---------------------------------------------------------*/

table {
    width: 100% !Important;
}

table tr td {
    border: none;
    padding: 0.5rem;
    vertical-align: top;
}

table tr {
    width: 100%;

}

table tr:nth-child(even) {

}


/** galleri **/



.multicolumns {
    column-count: 3;
    column-gap: .3rem;
}

.multicolumns > * {
    display: inline-block;
}

.gallery-item {
    position:relative;
    overflow:hidden;
}

.gallery-item a {
    display:block;
    width:100%;
    height:100%;
    transition: .5s ease;
}

.gallery-item a:hover:before {
    position:absolute;
    top:0;
    content:"";
    background:rgba(255,255,255,0.4);
    width:100%;
    height:100%;
    z-index:100;
    transition: .5s ease;
}

.gallery-item a img {
    display:block;
}

/* Boxar / Zoomboxar
-------------------------------------------------*/

.boxes a {
    text-decoration: none;
    color: #fff;
}

.boxes a:hover {
    color: #ccc;
}

.boxbutton, button, input[type=reset], input[type=submit] {
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 0.5rem;
}

.boxwrapper {
    height: 25vh;
    background-size: cover;
    background-position: center;
}

.zoombox .boxwrapper {
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.1);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index: 101;
    opacity: 0;
    text-align: center;
    transition: 0.5s ease;
    color: white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity: 1;
}


/* Contact
---------------------------------------------------------*/


.kontakt svg {
    margin-right: 0.5rem;
    fill: #777;
}


/* listing items
---------------------------------------------------------*/

.kontaktperson {
    background: var(--accent-color);
    text-align: center;
    color: #eee;
}

.listing-item {
    margin-right: 0;
    padding-right: 0;
    background: var(--main-color);
    margin-bottom: 10px;
}


.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color: white;
}

.pagination ul li a {
    text-decoration: none;
}

/* Footer
---------------------------------------------------------*/

footer {
    background: var(--main-color);
    color: #777;
    border-top: 1px solid #eee;
}

footer h2 {
    color: #777;
}

footer a {
    text-decoration: none;
}

footer svg.fa-icon {
    fill: #777;
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
}

footer svg.fa-icon:hover {
    fill: var(--accent-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/* Intendit footer
-------------------------------------------------*/
.created-by {
    background: var(--accent-color);
    padding: 0.5em 0;
    text-align: right;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a {
    color: #777;
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
 /* IE fix */;
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin: 0;
}


/* general
---------------------------------------------------------*/


.boltform *, .boltform input[type] {
    border-radius: 0px;
}

.boltform {
    padding: 1rem;
}

.boltform textarea {
    min-height: 150px;
}

.boltform input, .boltform textarea {
    color: #eee;
    background: #777;
    border: none;
    width: 100%;
}

.boltform .boltforms-row label.required:after {
    content: " *";
}

button[type=submit], input[type=submit] {
    background: #222;
    color: #fff;
    transition: 0.2s ease-in 0s;
    border: none;
    padding: 0.8rem;
    float: right;
    margin: 0;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: #333;
    color: #fff;
}


.fa-map-marker {
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*Not found*/
.pagenotfound {
    min-height: 100vh;
    position: relative;
    text-align: center;
    font-size: 1.1em;
}

.pagenotfound .logo img {
    max-height: 70px;
    width: auto;
    max-width: 100%;
    display: block;
}

.pagenotfound ul {
    list-style-type: none;
    padding: 0;
    margin: 0.5em auto;
    width: 100%;
    max-width: 550px;
}

.pagenotfound li a {
    border-bottom: 1px dashed #d0d0d0;
    display: inline-block;
    padding: 10px 110px;
}

/* Media Queries
---------------------------------------------------------*/

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1.2 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1.5 * var(--scale-factor-s)rem);
    }
    
    .logo img {
        margin-top: -20px;
        max-width: 160px;
    }

    .intenditfooter {
        text-align: center;
    }
    
    .introtext-outer {
    position: absolute;
    top: 35vh;
    max-width: 100%;
    left: 10%;
    }
    
    .introtext-inner {
        width: 80vw;
    }
    
    .introtext-inner h1 {
       font-size: 1.3rem;
    }
    
    .introtext-outer:before {
        display: none;
    }
    
    table tr td {
        width: 100%;
    }
}


@media (max-width: var(--breakpoint-xs)) {
    
    footer {
        text-align: center;
    }
    
}

@media (max-width: var(--breakpoint-menu)) {
   
    .menu .dropdown ul {
        border: none !important;
        background: var(--accent-color) !important;
        width: 100%;
        padding-left: 30px;
    }

    .menu .dropdown ul li a {
        font-size: .8rem;
        border: none;
    }

    .menu li, .menu li ul, .menu li:hover ul {
        margin: 0 !Important;
    }

    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
        background: var(--accent-color);
        color: #fff;
        border: none;
    }

    .menu .active a {
        color: #222;
        background: var(--effect-color) !important;
    }
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 1px solid;
    }
    
    #menu-icon {
        padding-top: 50px;
    }
    
    .menu.menu .active a {
        color: #222;
    }
}


@media (max-width: 1100px) {
/*Slick arrows*/   
    .topslider .slick-prev, .topslider .slick-next {
        height: 70px;
        width: 70px;
    }

    .topslider .slick-prev:before, .topslider .slick-next:before {
        font-size: 70px;
    }
}

.localeswitcher.active {
    display:none;
}


@media (max-width: 1280px) {
  
.menu li a {
    padding: 8px;
    font-size: .8rem;
}

}

.localeswitcher.active {
    display:none;
}


@media (max-width: 1024px) {
  


}

.localeswitcher.active {
    display:none;
}


/********** FB *************/
@media (min-width: var(--breakpoint-s)) {
    .news-block {
        top: 20em;
        right:12em;
        bottom: 5em;
        width: 50%;
        padding: 1em 2em;
        width: 30%;
        overflow: hidden;
    }
    

    mark {
    background: none;
     color: #222}

    .news-block-inner {
        overflow-y:scroll;
        height:300px;
    }
}

.news-post p  {
    margin-top:0;
}

.button {
    margin: 0;
    background: var(--effect-color);
    color: #222;
    border-radius: 0;
    border: none;
}

.button:hover {
    background: #333;
}
